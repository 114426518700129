@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Metropolis Extra";
  src: url("Metropolis-ExtraBold.woff2") format("woff2"),
    url("Metropolis-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Metropolis-Medium.woff2") format("woff2"),
    url("Metropolis-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Metropolis-Bold.woff2") format("woff2"),
    url("Metropolis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Metropolis-Regular.woff2") format("woff2"),
    url("Metropolis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Semi";
  src: url("Metropolis-SemiBold.woff2") format("woff2"),
    url("Metropolis-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Header */
/* navbar */
/* .hero-section {
  background: #f2f7fa;
} */
/* .logo {
  height: 2rem;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}
a:focus-within {
  outline: none !important;
}
p {
  font-family: "Catamaran", sans-serif;
}
li {
  list-style: none;
}
.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}
.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-bottom: 0;
}
.nav-menu li {
  list-style: none;
}
.nav-menu li a {
  text-decoration: none;
  color: #1b1b1b;
  font-size: 1.1rem;
  font-weight: 500;
}
.nav-menu li a:hover {
  background: linear-gradient(180deg, #73f5f7 0%, #1199dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
/* Hamburger */
/* Hero */

.hero_title {
  font-style: normal;
  padding-bottom: 1rem;
  font-family: "Lato", sans-serif !important;
  /* or 114% */
  color: #1b1b1b;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
}
.business_title {
  background: #25d366;
  background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero_content {
  /* font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: #000;
  width: 100%; */

  font-family: "Catamaran", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  color: rgba(27, 27, 27, 0.5);
  margin: 15px 0px 25px;
}

.hero_btn {
  font-size: 19px;

  background: linear-gradient(104.67deg, #25d366 8.05%, #0088cc 106.68%);
  color: #ffffff;
  padding: 30px 25px 35px;
  border-radius: 5px;
  /* border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(
    104.67deg,
    #25d366 8.05%,
    #0088cc 106.68%
  ); */
  border: none;
}
.hero_aside {
  padding: 140px 0px;
}
.pt-75 {
  padding-top: 75px;
}

/* ================================================== */
/* .header-nav { */
/* height: 4.5rem; */
/* width: 100vw;
  background-color: transparent; */
/* box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2); */
/* display: flex; */
/* position: fixed;
  z-index: 10;
  justify-content: space-between; */
/* } */

/*Styling logo*/
/* .logo {
  padding: 1vh 1vw;
  text-align: center;
} */

.logo img {
  /* height: 4.5rem; */
  width: 52%;
}

/*Styling Links*/
.nav-links {
  display: flex;
  list-style: none;
  justify-content: right;
  align-items: center;
}
.nav-links li {
  margin: 0 30px;
}
.nav-links li a {
  text-decoration: none;
  margin: 0 0.7vw;
}

.nav-links li a:hover {
  color: #61dafb;
}

.nav-links li {
  position: relative;
  font-weight: 600;
}
/* .nav-links li:hover {
  cursor: pointer;
  background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.nav-links li a::before {
  content: "";
  display: block;
  height: 3px;
  width: 0%;
  background-color: #61dafb;
  position: absolute;
  transition: all ease-in-out 250ms;
  margin: 0 0 0 10%;
  bottom: -7px;
  margin: auto;
  left: 0px;
  right: 0px;
}

.nav-links li a:hover::before {
  width: 92%;
}

/*Styling Buttons*/
.login-button {
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  margin-left: 2vw;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:hover {
  color: #131418;
  background-color: #f2f5f7;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

.join-button {
  color: #131418;
  background-color: #61dafb;
  border: 1.5px solid #61dafb;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
}

.join-button:hover {
  color: #f2f5f7;
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  background: #000;
  margin: 5px;
  transition: all 0.3s ease;
}

.hamburger {
  display: none;
}

.mb-nav {
  display: none;
}

.container-navmb {
  max-width: 1050px;
  width: 100%;
  margin: auto;
}

.navbar-mb {
  width: 100%;
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
}

.nav-container-mb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.navbar-mb .menu-items {
  display: flex;
}

.navbar-mb .nav-container li {
  list-style: none;
}

.navbar-mb .nav-container a {
  text-decoration: none;
  color: #0e2431;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar-mb .nav-container a:hover {
  font-weight: bolder;
}

.nav-container-mb {
  display: block;
  position: relative;
  height: 60px;
}

.nav-container-mb .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container-mb .hamburger-lines {
  display: block;
  height: 25px;
  width: 32px;
  position: absolute;
  top: 22px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container-mb .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container-mb .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container-mb .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container-mb .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar-mb .menu-items {
  padding-top: 120px;
  height: 100vh;
  width: 100%;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  padding-left: 50px;
  transition: transform 0.5s ease-in-out;
  text-align: center;
  background: #ffff;
}

.navbar-mb .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
  list-style: none;
}

.logo-mb {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 1.2rem;
  color: #0e2431;
}

.nav-container-mb input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container-mb input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container-mb input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container-mb input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container-mb input[type="checkbox"]:checked ~ .logo {
  display: none;
}

.frame {
  width: 100%;
  margin: 15px auto;
  /* text-align: center; */
}

.new-button {
  margin: 0px;
  outline: none;
}

.custom-newbtn {
  width: 200px;
  height: 42px;
  padding: 10px 25px;
  border: 2px solid #25d366;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}
.custom-newbtn span img {
  filter: brightness(40.25);
}
.btn-6 {
  background: #000;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
  background: linear-gradient(104.67deg, #25d366 8.05%, #0088cc 106.68%);
  border-radius: 3px;
  transition: all 0.3s ease-in;
}

.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: #25d366;
}

.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}

.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}

.btn-6:hover {
  color: #0088cc;
  font-weight: 500;
  background: transparent;
  transition: all 0.3s ease-in;
  /* background: #25d366;
  background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* background: transparent; */
}

.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}

.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}

.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  background: #25d366;
}

.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}

.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}

.btn-6 span:hover:before {
  width: 100%;
}

.btn-6 span:hover:after {
  width: 100%;
}
.side-hero {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 100px 0px;
}

/* .side-hero img {
  width: 85%;
} */
.bg-texture {
  background: url(../../assets/bg.png);
  background-size: auto;
  background-position: center;
  background-size: cover;
  -o-background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  margin-top: -135px;
}

/* responsive css====================================================================> */

@media screen and (max-width: 800px) {
  .nav-container-mb {
    display: block;
    position: fixed;
    height: 60px;
    width: 100%;
    top: 0px;
    background: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  .hero_aside {
    padding: 0px 0px;
  }

  .mb-nav {
    display: block !important;
  }

  .desk-nav {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .desk-nav {
    display: block;
  }
  .bg-texture {
    height: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .bg-texture {
    height: 100%;
  }
  .how_content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #1b1b1b;
    margin: 20px 0px;
    text-align: justify;
  }
  .how_title2 {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 2rem !important;
    line-height: 40px !important;
    color: #090909;
  }
  .how_section {
    background: #fff;
    padding: 20px 0;
  }
  .how_title {
    margin-bottom: 0.5rem;
  }
  .hero_title {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 50px;
    padding-bottom: 1rem;
    color: #1b1b1b;
    text-align: start;
    letter-spacing: 1px;
  }
  .our_head {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 20px;
  }
  .services {
    padding: 30px 0;
  }
  .logo-mb {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 1.2rem;
    color: #0e2431;
    margin: 7px 5px;
  }
  .img-logo {
    width: 130px;
  }
  .hero-img {
    width: 90%;
  }
  .hero_content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #1b1b1b;
    margin: 20px 0px;
    text-align: justify;
    width: 100%;
    /* font-size: 1.2rem;
      line-height: 0px;
      line-height: 33px;
      color: #000;
      width: 100%;
      margin: 0; */
  }
}

.nav-links li a {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 20px;
  color: #1b1b1b;
  transition: 0.3s;
}

.header .row {
  align-items: center;
}

.hero-section {
  padding: 40px 0px;
  z-index: 99;
  position: relative;
}

.nav-links li a {
  position: relative;
}
.nav-links li a img {
  width: 12px;
  top: 6px;
  position: absolute;
  right: -15px !important;
}

.custom-newbtn:hover span img {
  filter: none !important;
}

.hero_aside h6 {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: #00b2ff;
}

.justify-content-right {
  justify-content: right;
}
