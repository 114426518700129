* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.contact {
  background: #f2f7fa;
}
.waiting {
  background: #25d366;
  background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.small {
  display: block;
  text-align: center;
}
.mt-2rem {
  margin-top: 2rem;
}
.contact-mid {
  padding: 0rem 10rem;
}
.just_btn {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.text-msg {
  border: 1px solid #d3c9c9;
  border-radius: 5px;
}
input,
textarea {
  border: none !important;
  background: #ffffff;
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.05);
  outline: none;
  padding: 0.5rem 2rem;
}
input:focus {
  border: none !important;
}
  











.background {
  display: flex;
  margin-bottom: 80px;
}

.container-contact {
  flex: 0 1 1000px;
  margin: auto;
  padding: 10px;
}

.screen {
  position: relative;
  background: #caeff4;
  border-radius: 10px;
  top: 50px;
}

.screen:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #ed1c6f;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #25d366;
  font-size: 26px;
}

.app-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 45px;
  height: 3px;
  background: #25d366;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: #888;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  /* margin-top: 40px; */
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
    width: 100%;
      padding: 10px 10px;
      background: #fff;
      border: none;
      border-bottom: 1px solid #666;
      color: #000;
      font-size: 14px;
      outline: none;
      transition: border-color .2s;
      border-radius: 5px;

}

.app-form-control::placeholder {
  color: #666;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}

.app-form-button {
  font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 0px;
    background: linear-gradient(104.67deg, #25d366 8.05%, #0088cc 106.68%);
    padding: 18px 30px;
    color: #fff;
    border-radius: 3px;
  border: none;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #25d366;
}

.credits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffa4bd;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.credits-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}
.con-dtl li img {
  border: 1px solid #0088cc;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50px;
}
.con-dtl li a {
  text-decoration: none;
  margin-left: 10px;
  color: #000000;
  font-size: 15px;
  font-family: Lato;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;


}

.con-dtl li {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.contact-home{
  padding: 60px 0px;
  position: relative;
}

section.contact-home::before {
  content: "";
  background: linear-gradient( 153.86deg, rgba(0, 178, 255, 0.25) 27.48%, rgba(0, 178, 255, 0.15) 109.43% );
  filter: blur(290px);
  border-radius: 15.5689px;
  position: absolute;
  width: 63%;
  height: 100%;
  background: linear-gradient( 153.86deg, rgba(0, 178, 255, 0.35) 27.48%, rgba(37, 211, 102, 0.15) 109.43% );
  filter: blur(290px);
  border-radius: 0;
  top: 0px;
  right: 0px;
}