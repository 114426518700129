.lists-ot li {
  line-height: 30px;
  padding-bottom: 14px;
  position: relative;
  padding-left: 30px;
  font-size: 18px;
  font-family: "Catamaran", sans-serif;
}
.lists-ot li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 61px;
  top: 12px;
  left: 12px;
}
