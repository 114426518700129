.tab-list li {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  padding: 20px 80px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
}

.tab-list li.active {
  background: #e6f8ff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border: 1px solid transparent;
}

.tab-content {
  padding: 50px;
}
.tab-content p {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 43px;
  color: #555555;
}

.tabs-main {
  margin-top: 50px;
}

.faq-tab {
  border: 1px solid #e9e9e9;
  padding: 20px 30px;
  cursor: pointer;
  margin-bottom: 30px;
}
.faq-tab h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.faq-title img {
  position: absolute;
  right: 20px;
  top: 9px;
}
.faq-title {
  position: relative;
}
.faq-cont {
  margin-top: 20px;
}
.faq-active {
  background: #e6f8ff;
}
.faq-tab p {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #1b1b1b;
}

/* .wats-oofcial-out {
  background: linear-gradient(
    153.86deg,
    rgba(0, 178, 255, 0.35) 27.48%,
    rgba(37, 211, 102, 0.35) 109.43%
  );
  backdrop-filter: blur(290px);
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
} */
.wats-oofcial-out{position: relative;
padding: 50px 0px;}
  section.wats-oofcial-out::before {
  content: "";
  background: linear-gradient( 153.86deg, rgba(0, 178, 255, 0.15) 27.48%, rgba(37, 211, 102, 0.15) 109.43% );
  filter: blur(290px);
  border-radius: 15.5689px;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    153.86deg,
    rgba(0, 178, 255, 0.35) 27.48%,
    rgba(37, 211, 102, 0.35) 109.43%
  ); */
  filter: blur(290px);
  border-radius: 15.5689px;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 75%;
  margin: auto;
}
