* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.how_section {
  background: #fff;
}
.how_title {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  /* identical to box height */
  text-transform: uppercase;
  background: #25d366;
  background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.how_title2 {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 55px;
  color: #090909;
}
.how_content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #1b1b1b;
  margin: 30px 0px 15px;
}
.how_order li {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  color: #090909;
}
.how_order li::marker {
  color: #0088cc;
  background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.how_btn {
  margin-top: 1.5rem;
}
@media screen and (min-width:320px) and (max-width:425px) {

  .how_content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #1b1b1b;
    margin: 20px 0px;
    text-align: justify;
  }
  .step-img{
    width: 90%;
  }
}