.side-heroo {
  padding: 100px 0px 0px;
}

.integrate-cnt {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(9, 9, 9, 0.04);
  padding: 40px;
  min-height: 325px;
}

.integrate-cnt h4 {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}
.integrate-cnt p {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 0px;
}
.connet-outer {
  position: relative;
  padding: 120px 0px 70px;
}

section.connet-outer::before {
  content: "";
  background: linear-gradient( 153.86deg, rgba(0, 178, 255, 0.25) 27.48%, rgba(0, 178, 255, 0.25) 109.43% );
  filter: blur(290px);
  border-radius: 15.5689px;
  position: absolute;
  width: 63%;
  height: 100%;
  /* background: linear-gradient( 153.86deg, rgba(0, 178, 255, 0.35) 27.48%, rgba(37, 211, 102, 0.35) 109.43% ); */
  filter: blur(290px);
  border-radius: 0;
  top: 0px;
  right: 0px;
}
section.connet-outer .row {
  align-items: center;
  position: relative;
  z-index: 99;
}
section.connet-outer .row .col-md-5 h3{
font-family: 'Metropolis';
font-style: normal;
font-weight: 600;
font-size: 45px;
line-height: 60px;
color: #090909;
}
section.connet-outer .row .col-md-7 ul li {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  padding-bottom: 35px;
  padding-left: 43px;
  position: relative;
}
section.connet-outer .row .col-md-7 ul li span {
  position: absolute;
  left: 0px !important;
  background: #FFFFFF;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50px;
  color: #00B2FF;
  font-size: 17px;
  top: -5px;
}