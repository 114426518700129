* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.services {
  padding: 100px 0 40px;
}
/* .our_head {
font-weight: 600;
  font-size: 1.5rem;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(300.04deg, #25d366 6.89%, #08c 79.97%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
} */

.our_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 60px;
  color: #1b1b1b;
  margin-bottom: 20px;
}

/* .high_text {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  color: #090909;
  line-height: 35px;
  margin-bottom: 1rem;
} */
.high_text {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: #00b2ff;
  margin-bottom: 10px;
}
/* .custom_text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #1b1b1b;
} */
.custom_text {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #1b1b1b;
}
.serv_hed {
  height: 21.05px;
  font-style: normal;
  font-weight: 600;
  font-size: 20.0712px;
  line-height: 20px;
  color: #090909;
  margin-bottom: 15px;
}
.serv_cont {
  /* height: 70px; */
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1219px;
  line-height: 20px;
  color: #8b8b8b;
  min-height: 100px;
}
/* .serv_cont {
  height: 70px;
  font-style: normal;
  font-weight: 400;
  font-size: 12.0427px;
  color: #8b8b8b;
} */
/* .know_more {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 0px;
  background: linear-gradient(104.67deg, #25d366 8.05%, #0088cc 106.68%);
  margin: 0px 65px;
  padding: 18px 0px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
} */
.know_more {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 0px;
  /* background: linear-gradient(104.67deg, #25d366 8.05%, #0088cc 106.68%); */
  /* margin: 0px 65px; */
  /* padding: 18px 0px; */
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 0px;
}
.know_more a {
  text-decoration: none;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: #00b2ff;
}
.service_box {
  text-align: left;
  width: auto;
  height: auto;
  /* background: #f2f7fa; */
  background: #fff;
  padding: 30px 25px;
  /* box-shadow: 0 0.125rem 0.625rem 0 rgb(0 0 0 / 20%); */
  border: 1px solid #e9e9e9;
  margin-bottom: 1.5rem;
}
