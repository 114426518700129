section.blog-home {
  position: relative;
  background: #fff;
  z-index: 9;
}
.blog-item {
  background: #fafafa;
}
.blog-item-txt {
  padding: 25px;
}
.blog-item img {
  width: 100%;
}
.blog-item-txt h4{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 28px;
}
.blog-item-txt a {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #00b2ff;
  text-decoration: none;
  position: relative;
}
.blog-item-txt a img {
    width: 10px;
    position: absolute;
    top: 9px;
  }
.blog-home h2{
    text-align: left;
}
.blog-home .row .col-md-6 button {
    float: right;
    background: transparent;
    border: 1.6px solid #00B2FF;
    padding: 9px 30px;
    color: #00B2FF;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #00B2FF;
  }
  .blog-home .row .col-md-6 button img{
    width: 10px;
  }