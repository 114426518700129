* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.ptb-50 {
  padding: 50px 0;
}
.bot-build{
  background: #f2f7fa;
}
.how_title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 24px;
  /* identical to box height */
  text-transform: uppercase;
  background: #25d366;
  background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
}
.herotitle {
    font-style: normal;
      font-weight: 700;
      font-size: 3rem;
      color: #1b1b1b;
      padding-bottom: 1rem;
}
.align-img {
  display: flex;
  justify-content: center;
}
@media screen and (min-width:320px) and (max-width:425px) {

  .herotitle {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 40px;
    color: #1b1b1b;
    padding-bottom: 1rem;
  }
}