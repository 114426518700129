.testi-txt {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(9, 9, 9, 0.04);
  padding: 35px;
  position: relative;
}
.auther-nm.d-flex {
  align-items: center;
}

.auther-nm.d-flex h5 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 0;
  margin-left: 15px;
}

.testi-txt p {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;

  color: #000000;
}

.quotes-im {
  position: absolute;
  bottom: 35px;
  right: 36px;
}
.testimonial-out {
    position: relative;
    padding: 65px 0px;
  }
  section.testimonial-out::before {
    content: "";
    background: linear-gradient( 153.86deg, rgba(0, 178, 255, 0.15) 27.48%, rgba(37, 211, 102, 0.15) 109.43% );
    filter: blur(290px);
    border-radius: 15.5689px;
    position: absolute;
    width: 100%;
    height: 46%;
    background: linear-gradient( 90deg, rgba(0, 178, 255, 0.35) 27.48%, rgba(37, 211, 102, 0.35) 109.43% );
    filter: blur(290px);
    border-radius: 15.5689px;
    bottom: 59px;
    left: 0px;
    right: 0px;
    width: 75%;
    margin: auto;
}


