* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #fff;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #000;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 25px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  /* margin-bottom: 14px;
  font-size: 14px;
  color: #000;
  line-height: 28px; */
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1B1B1B;
}
.footer-textt p {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7B7B7B;
  padding-left: 32px;
  position: relative;
}
.footer-textt p a{
  color: #7B7B7B;
  text-decoration: none;
}
.footer-social-icon span {
  color: #000;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}
.footer-social-icon {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 26px;
  position: relative;
}
/* .footer-widget-heading h3::before {
    content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      height: 2.5px;
      width: 100px;
      background: #067496;
} */
/* .footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
} */
.footer-widget ul li:hover {
  color: #0796b9;
}
.footer-widget ul li {
  cursor: pointer;
  text-transform: capitalize;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #7B7B7B !important;
  padding-bottom: 16px;
}
.footer-widget ul li a{
  cursor: pointer;
  text-transform: capitalize;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #7B7B7B !important;
  text-decoration: none;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.location {
  display: flex;
}
.social-icon li {
  width: 20% !important;
}
.footer-menu ul li {
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 9px 28px;
  background: #fff;
  border: 1px solid #2e2e2e !important;
  color: #fff;
  border-radius: 5px;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 9px 20px;
  border: none;
  top: 0;
  border-radius: 0px 5px 5px 0px;
  background: linear-gradient(104.67deg, #25d366 8.05%, #0088cc 106.68%);
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  border-top: 2px solid rgba(12, 161, 170, 0.1);
  background: #fff;
  padding: 22px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p {
  /* background: linear-gradient(to right, #25d366 0%, #0088cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  font-weight: 600; */

  font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 22px;

color: #090909;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover {
  color: #ff5e14;
}
.footer-menu li {
  font-size: 14px;
  color: #878787;
}


.footer-content .row .col-xl-5 {
  flex: 0 0 auto;
  width: 42% !important;
}
.footer-content .row .col-xl-2 {
  flex: 0 0 auto;
  width: 19% !important;
}

.footer-textt img {
  width: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
}